import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  setElapsedTime,
  setMeterValues,
} from "@/redux/reducers/session/session.slice";
import { Client } from "@stomp/stompjs";
import { formatTime } from "@/lib/utils/date";

const ChargingSession = () => {
  const dispatch = useDispatch();

  // const [elapsedTime, setElapsedTime] = useState(0);

  const { meterValues, elapsedTime } = useSelector((state) => state.session);
  const { chargePointInfo, transaction, isCharging } = useSelector(
    (state) => state.charger
  );
  const { currency } = useSelector((state) => state.wallet);

  useEffect(() => {
    let timerInterval;

    // adds 1 to elapsedTime every 1000ms, if there is a transaction started
    if (isCharging && transaction) {
      // dispatch(setElapsedTime(0));
      timerInterval = setInterval(() => {
        dispatch(setElapsedTime(elapsedTime + 1));
      }, 1000);
    } else {
      clearInterval(timerInterval);
    }

    // Cleanup interval on component unmount or when isCharging changes
    return () => clearInterval(timerInterval);
  }, [isCharging, transaction, dispatch, elapsedTime]);

  useEffect(() => {
    // RabbitMQ connection details
    const RABBITMQ_URL = `wss://${import.meta.env.VITE_RABBITMQ_HOST}/ws`; // WebSocket URL of your RabbitMQ server

    const RABBITMQ_USER = import.meta.env.VITE_RABBITMQ_USER;
    const RABBITMQ_PASSWORD = import.meta.env.VITE_RABBITMQ_PASSWORD;
    const EXCHANGE_NAME = "gridflow_drive_exchange";
    const RABBITMQ_VHOST = "grdflo_drive_host";
    const getRoutingKey = () =>
      `charge_session.${chargePointInfo.charge_point_id}.${transaction.transaction_id}`;

    let client = null; // Initialize client reference
    let subscription = null; // Initialize subscription reference

    const connectAndSubscribe = () => {
      client = new Client({
        brokerURL: RABBITMQ_URL,
        connectHeaders: {
          login: RABBITMQ_USER,
          passcode: RABBITMQ_PASSWORD,
          host: RABBITMQ_VHOST,
        },
        reconnectDelay: 5000,
        heartbeatIncoming: 4000,
        heartbeatOutgoing: 4000,
      });

      client.onConnect = () => {
        const onReceiveMessage = (message) => {
          const item = JSON.parse(message.body);
          dispatch(setMeterValues({ ...item, duration: elapsedTime }));
          message.ack();
        };

        const headers = {
          "x-bindings": JSON.stringify([
            { exchange: EXCHANGE_NAME, routing_key: getRoutingKey() },
          ]),
        };

        // Subscribe to the desired exchange and routing key
        subscription = client.subscribe(
          `/exchange/${EXCHANGE_NAME}/${getRoutingKey()}`,
          onReceiveMessage,
          {
            ...headers,
            ack: "client",
          }
        );
      };

      client.onStompError = function (frame) {
        console.log("Broker reported error: " + frame.headers["message"]);
        console.log("Additional details: " + frame.body);
      };

      client.activate();
    };

    if (isCharging && transaction) {
      console.log("Connecting to RabbitMQ...");
      console.log("Transaction ID: ", transaction?.transaction_id);
      connectAndSubscribe();
    }

    return () => {
      if (subscription) {
        subscription.unsubscribe(); // Unsubscribe from RabbitMQ messages
      }
      if (client) {
        client.deactivate(); // Disconnect from RabbitMQ
        client = null; // Reset client reference
      }
    };
  }, [chargePointInfo, dispatch, elapsedTime, isCharging, transaction]);

  return (
    <div className=" p-4  rounded-[30px] bg-[#CECECE30] w-full grid grid-cols-2 gap-4 items-start">
      <div className="flex items-start flex-col gap-1">
        <span className="text-sm font-light">Charging Rate</span>
        <div className=" text-2xl font-bold">
          {meterValues.charging_rate || 0} kW
        </div>
      </div>
      <div className="flex items-start flex-col gap-1">
        <span className="text-sm font-light">Time Elapsed</span>
        <div className=" text-2xl font-bold">{formatTime(elapsedTime)}</div>
      </div>{" "}
      <div className="flex items-start flex-col gap-1">
        <span className="text-sm font-light">Energy Consumed</span>
        <div className=" text-2xl font-bold">
          {meterValues.energy_consumed || 0} kWh
        </div>
      </div>{" "}
      <div className="flex items-start flex-col gap-1">
        <span className="text-sm font-light">Cost of Charging</span>
        <div className=" text-2xl font-bold">
          {currency} {meterValues.total_price || 0}
        </div>
      </div>
    </div>
  );
};

export default ChargingSession;
