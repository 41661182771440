import { ChargerBoxesHeader } from "./charger-boxes-header";

export const ChargerPluginBoxSuccessful = () => {
  return (
    <div className="  p-4  rounded-2xl bg-[#CECECE30] flex flex-col items-center gap-3 ">
      <ChargerBoxesHeader status="free" />
      <div>
        <img src="/images/charger-plugin.png" />
      </div>
      <div>
        <p className="text-xs font-thin max-w-[205px]">
          Please plug in your car.
        </p>
      </div>
    </div>
  );
};
