import { ChargerPluginBoxSuccessful } from "@/components/charger-boxes/charger-plugin-box-successful";
import { Dialog, DialogContent, DialogTrigger } from "@/components/ui/dialog";
import { LightButton } from "@/components/ui/light-button";
import { NewUser } from "@/components/welcome-modals/new-user";
import { ReturningUser } from "@/components/welcome-modals/returning-user";
import { ReturningUserIncomplete } from "@/components/welcome-modals/returning-user-incomplete";
import { Wrapper } from "@/components/wrapper";
import { useGetUserProfileQuery } from "@/redux/reducers/auth/auth.api";

import { setHasCheckedProfile } from "@/redux/reducers/auth/auth.slice";
import { ArrowRight, Navigation } from "lucide-react";
import { useState } from "react";
import { useEffect } from "react";

import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

export default function PluginCharger() {
  const { chargePointInfo } = useSelector((state) => state.charger);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [locationModalOpen, setLocationModalOpen] = useState(false);

  // const { chargePointInfo } = useSelector((state) => state.charger);
  const { hasCheckedProfile } = useSelector((state) => state.auth);
  // const [getChargerDetail, { isLoading }] = useLazyGetChargerDetailQuery();

  const [isNew, setIsNew] = useState(false);
  const [isReturning, setIsReturning] = useState(false);
  const [isReturningIncomplete, setIsReturningIncomplete] = useState(false);

  const { data: userProfileData } = useGetUserProfileQuery();

  // this part is commented until the app is fully tested with a real charger and not a siumlator
  const handleChargerStatus = () => {
    navigate("/start-charger");
    // getChargerDetail(chargePointInfo.charge_point_id, false)
    //   .unwrap()
    //   .then((response) => {
    //     if (response.data.charge_point_status === "Preparing") {
    //       toast.success("Charge point connected and preparing");
    //       navigate("/start-charger");
    //     } else {
    //       toast.error("Charge point not connected");
    //     }
    //   })
    //   .catch((error) => {
    //     toast.error(error.data.error_message);
    //   });
  };

  useEffect(() => {
    if (userProfileData && !hasCheckedProfile) {
      const { email, first_name, last_name } = userProfileData.data;
      dispatch(setHasCheckedProfile(true));
      setIsReturning(true);
      if (email && first_name && last_name) {
        const timeout = setTimeout(() => {
          setIsReturning(false);
        }, 500);

        return () => clearTimeout(timeout);
      } else if (email || first_name || last_name) {
        setIsReturning(false);
        setIsReturningIncomplete(true);
      } else {
        setIsReturning(false);
        setIsNew(true);
      }
    }
  }, [userProfileData, hasCheckedProfile, dispatch]);

  return (
    <Wrapper className="space-y-4">
      <ChargerPluginBoxSuccessful />
      <div className="text-sm max-w-[253px] mx-auto font-light space-y-4">
        <span>Please connect your car to the charger</span>

        {chargePointInfo.charge_point_location_description && (
          <Dialog modal open={locationModalOpen}>
            <DialogTrigger
              onClick={() => {
                setLocationModalOpen(true);
              }}
              asChild
            >
              <LightButton className="w-full" icon={Navigation}>
                View Location Instructions
              </LightButton>
            </DialogTrigger>
            <DialogContent
              onClose={() => {
                setLocationModalOpen(false);
              }}
            >
              <div>{chargePointInfo.charge_point_location_description}</div>
            </DialogContent>
          </Dialog>
        )}

        <LightButton
          className="w-full"
          onClick={handleChargerStatus}
          icon={ArrowRight}
          // disabled={isLoading}
        >
          Continue
        </LightButton>
      </div>
      <ReturningUser
        isOpen={isReturning}
        onClose={() => setIsReturning(false)}
      />
      <ReturningUserIncomplete
        isOpen={isReturningIncomplete}
        onClose={() => setIsReturningIncomplete(false)}
      />
      <NewUser isOpen={isNew} onClose={() => setIsNew(false)} />
    </Wrapper>
  );
}
