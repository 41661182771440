import { useSelector } from "react-redux";
import { Avatar, AvatarFallback, AvatarImage } from "@/components/ui/avatar";


export const Logo = ({ showChargerOwner }) => {
  const { chargePointInfo } = useSelector((state) => state.charger);
  return (
    <div className="flex items-center gap-4">
      {chargePointInfo && showChargerOwner ? (
        <div className="flex items-center gap-4">
          <Avatar className="relative flex shrink-0 rounded-full h-16 w-16">
            <AvatarImage src={chargePointInfo.charge_point_org_picture} />
            <AvatarFallback className="bg-[#baab71b7] text-4xl p-4 h-auto uppercase">
              {chargePointInfo?.charge_point_org_name?.charAt(0)}
            </AvatarFallback>
          </Avatar>
          <h1 className=" text-2xl capitalize">
            {chargePointInfo.charge_point_org_name}
          </h1>
        </div>
      ) : (
        <>
          <img width={34} height={31} src="/images/logo-small.png" alt="logo" />
          <h1 className="text-2xl">GridFlow Drive</h1>
        </>
      )}
    </div>
  );
};
