import { ChargerBoxesHeader } from "./charger-boxes-header";
import { useLazyGetChargersWithinDistanceQuery } from "@/redux/reducers/charger/charger.api";
import { useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { Button, buttonVariants } from "../ui/button";
import { Link } from "react-router-dom";
import { cn } from "@/lib/utils/cn";
import { useDispatch } from "react-redux";
import { setChargersWithinDistanceData } from "@/redux/reducers/charger/charger.slice";

export const ChargerBoxBusy = () => {
  const dispatch = useDispatch();

  const [maxDistance, setMaxDistance] = useState(5);

  const [trigger, { isLoading, isError, isSuccess }] =
    useLazyGetChargersWithinDistanceQuery();
  const { chargePointInfo, chargersWithinDistanceData } = useSelector(
    (state) => state.charger
  );

  useEffect(() => {
    async function getChargersWithinDistance() {
      try {
        const { data: chargersWithinDistanceData } = await trigger({
          latitude: chargePointInfo.charge_point_latitude,
          longitude: chargePointInfo.charge_point_longitude,
          max_distance: maxDistance,
        });
        dispatch(
          setChargersWithinDistanceData(chargersWithinDistanceData.data)
        );
      } catch (error) {
        console.error("error", error);
      }
    }

    if (chargePointInfo) {
      getChargersWithinDistance();
    }
  }, [chargePointInfo, dispatch, maxDistance, trigger]);

  return (
    <div className="  p-4  rounded-2xl bg-[#CECECE30] flex flex-col items-center gap-3 w-full ">
      <ChargerBoxesHeader status="busy" />

      {isLoading ? (
        <>Loading</>
      ) : isError ? (
        <>Error</>
      ) : isSuccess && chargersWithinDistanceData ? (
        <div>
          <p>There are currently</p>
          <div
            style={{ backgroundImage: "url(/images/ellipse.png)" }}
            className=" bg-center bg-no-repeat bg-cover flex items-center justify-center"
          >
            <Link
              to="/available-chargers"
              className={cn(
                buttonVariants({ variant: "link" }),
                " h-auto text-[89px] text-[#7EBC78]"
              )}
            >
              {chargersWithinDistanceData?.length >= 1
                ? chargersWithinDistanceData?.length
                : chargersWithinDistanceData?.length > 10
                ? "10+"
                : chargersWithinDistanceData?.length}
            </Link>
          </div>
        </div>
      ) : null}

      <div className="space-y-3">
        <p className="text-xs text-center font-thin max-w-[150px] mx-auto">
          available chargers within {maxDistance}km radius of here
        </p>
      </div>
    </div>
  );
};
