import { cn } from "@/lib/utils/cn";
import { Button } from "./button";
import React from "react";

export const LightButton = React.forwardRef(
  ({ children, className, icon: Icon, iconSide, ...props }, ref) => {
    return (
      <Button
        ref={ref}
        className={cn(
          "rounded-xl border-dashed border-[#9c8a4a5e] border shadow-[0px_0px_20px_0px_#FED127_inset] backdrop-blur-lg space-x-2",
          className
        )}
        {...props}
      >
        {!iconSide && iconSide !== "right" && (
          <Icon size={18} color="#FFFFFFCC" />
        )}
        <span>{children}</span>
        {iconSide == "right" && <Icon size={18} color="#FFFFFFCC" />}
      </Button>
    );
  }
);
