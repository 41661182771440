import React, { useState } from "react";
import { Skeleton } from "@/components/ui/skeleton";
import { useGetPastChargingSessionsQuery } from "@/redux/reducers/charger/charger.api";
import { PastSessionBox } from "@/components/wallet/past-session-box";
import { Button } from "../ui/button";

const SessionsList = () => {
  const [currentPage, setCurrentPage] = useState(1);

  const {
    data: chargingSessionsData,
    isLoading: chargingSessionsIsLoading,
    isError: chargingSessionsIsError,
    isSuccess: chargingSessionsIsSuccess,
    isFetching: chargingSessionsIsFetching,
  } = useGetPastChargingSessionsQuery({
    page: currentPage,
  });

  return (
    <div>
      <h2 className="text-xl font-light text-left pb-5">Past Sessions</h2>
      {chargingSessionsIsLoading ? (
        <div className="space-y-4">
          <Skeleton className="rounded-lg h-[45px]" />
          <Skeleton className="rounded-lg h-[45px]" />
          <Skeleton className="rounded-lg h-[45px]" />
        </div>
      ) : chargingSessionsIsError ? (
        <>Error with getting charging sessions</>
      ) : chargingSessionsIsSuccess ? (
        <div className="space-y-4 max-h-[300px] overflow-y-auto">
          {chargingSessionsData.results &&
          chargingSessionsData.results.length > 0 ? (
            <>
              {chargingSessionsData.results.map((item, index) => (
                <PastSessionBox
                  // status={item.status}
                  key={index}
                  address={item.charger_location}
                  energyConsumed={item.energy_consumed}
                  cost={item.amount}
                  date={item.end_time}
                />
              ))}
              {chargingSessionsData.next && (
                <div className="text-center">
                  <Button
                    onClick={() => {
                      setCurrentPage(currentPage + 1);
                    }}
                    disabled={
                      chargingSessionsIsLoading || chargingSessionsIsFetching
                    }
                  >
                    Load More
                  </Button>
                </div>
              )}
            </>
          ) : (
            <div className="text-center p-4 bg-[#cecece09] rounded-2xl text-[#FFFFFFCC]">
              No Transactions Yet
            </div>
          )}
        </div>
      ) : (
        <></>
      )}
    </div>
  );
};

export default SessionsList;
