export const currencyList = [
  {
    country: "AD",
    currency: "\u20ac",
  },
  {
    country: "AE",
    currency: "\u062f.\u0625.\u200f",
  },
  {
    country: "AF",
    currency: "\u060b",
  },
  {
    country: "AG",
    currency: "$",
  },
  {
    country: "AI",
    currency: "$",
  },
  {
    country: "AL",
    currency: "Lek\u00eb",
  },
  {
    country: "AM",
    currency: "\u058f",
  },
  {
    country: "AO",
    currency: "Kz",
  },
  {
    country: "AR",
    currency: "$",
  },
  {
    country: "AS",
    currency: "$",
  },
  {
    country: "AT",
    currency: "\u20ac",
  },
  {
    country: "AU",
    currency: "$",
  },
  {
    country: "AW",
    currency: "Afl.",
  },
  {
    country: "AX",
    currency: "\u20ac",
  },
  {
    country: "AZ",
    currency: "\u20bc",
  },
  {
    country: "BA",
    currency: "KM",
  },
  {
    country: "BB",
    currency: "$",
  },
  {
    country: "BD",
    currency: "\u09f3",
  },
  {
    country: "BE",
    currency: "\u20ac",
  },
  {
    country: "BF",
    currency: "CFA",
  },
  {
    country: "BG",
    currency: "\u043b\u0432.",
  },
  {
    country: "BH",
    currency: "\u062f.\u0628.\u200f",
  },
  {
    country: "BI",
    currency: "FBu",
  },
  {
    country: "BJ",
    currency: "CFA",
  },
  {
    country: "BL",
    currency: "\u20ac",
  },
  {
    country: "BM",
    currency: "$",
  },
  {
    country: "BN",
    currency: "$",
  },
  {
    country: "BO",
    currency: "Bs",
  },
  {
    country: "BQ",
    currency: "$",
  },
  {
    country: "BR",
    currency: "R$",
  },
  {
    country: "BS",
    currency: "$",
  },
  {
    country: "BT",
    currency: "Nu.",
  },
  {
    country: "BW",
    currency: "P",
  },
  {
    country: "BY",
    currency: "\u0440.",
  },
  {
    country: "BZ",
    currency: "$",
  },
  {
    country: "CA",
    currency: "$",
  },
  {
    country: "CC",
    currency: "$",
  },
  {
    country: "CD",
    currency: "FC",
  },
  {
    country: "CF",
    currency: "FCFA",
  },
  {
    country: "CG",
    currency: "FCFA",
  },
  {
    country: "CH",
    currency: "CHF",
  },
  {
    country: "CI",
    currency: "CFA",
  },
  {
    country: "CK",
    currency: "$",
  },
  {
    country: "CL",
    currency: "$",
  },
  {
    country: "CM",
    currency: "FCFA",
  },
  {
    country: "CN",
    currency: "\u00a5",
  },
  {
    country: "CO",
    currency: "$",
  },
  {
    country: "CR",
    currency: "\u20a1",
  },
  {
    country: "CU",
    currency: "$",
  },
  {
    country: "CV",
    currency: "\u200b",
  },
  {
    country: "CW",
    currency: "NAf.",
  },
  {
    country: "CX",
    currency: "$",
  },
  {
    country: "CY",
    currency: "\u20ac",
  },
  {
    country: "CZ",
    currency: "K\u010d",
  },
  {
    country: "DE",
    currency: "\u20ac",
  },
  {
    country: "DG",
    currency: "US$",
  },
  {
    country: "DJ",
    currency: "Fdj",
  },
  {
    country: "DK",
    currency: "kr.",
  },
  {
    country: "DM",
    currency: "$",
  },
  {
    country: "DO",
    currency: "RD$",
  },
  {
    country: "DZ",
    currency: "\u062f.\u062c.\u200f",
  },
  {
    country: "EA",
    currency: "\u20ac",
  },
  {
    country: "EC",
    currency: "$",
  },
  {
    country: "EE",
    currency: "\u20ac",
  },
  {
    country: "EG",
    currency: "\u062c.\u0645.\u200f",
  },
  {
    country: "EH",
    currency: "\u062f.\u0645.\u200f",
  },
  {
    country: "ER",
    currency: "Nfk",
  },
  {
    country: "ES",
    currency: "\u20ac",
  },
  {
    country: "ET",
    currency: "Br",
  },
  {
    country: "FI",
    currency: "\u20ac",
  },
  {
    country: "FJ",
    currency: "$",
  },
  {
    country: "FK",
    currency: "\u00a3",
  },
  {
    country: "FM",
    currency: "US$",
  },
  {
    country: "FO",
    currency: "kr",
  },
  {
    country: "FR",
    currency: "\u20ac",
  },
  {
    country: "GA",
    currency: "FCFA",
  },
  {
    country: "GB",
    currency: "\u00a3",
  },
  {
    country: "GD",
    currency: "$",
  },
  {
    country: "GE",
    currency: "\u20be",
  },
  {
    country: "GF",
    currency: "\u20ac",
  },
  {
    country: "GG",
    currency: "\u00a3",
  },
  {
    country: "GH",
    currency: "GH\u20b5",
  },
  {
    country: "GI",
    currency: "\u00a3",
  },
  {
    country: "GL",
    currency: "kr.",
  },
  {
    country: "GM",
    currency: "D",
  },
  {
    country: "GN",
    currency: "FG",
  },
  {
    country: "GP",
    currency: "\u20ac",
  },
  {
    country: "GQ",
    currency: "FCFA",
  },
  {
    country: "GR",
    currency: "\u20ac",
  },
  {
    country: "GT",
    currency: "Q",
  },
  {
    country: "GU",
    currency: "$",
  },
  {
    country: "GW",
    currency: "CFA",
  },
  {
    country: "GY",
    currency: "$",
  },
  {
    country: "HK",
    currency: "HK$",
  },
  {
    country: "HN",
    currency: "L",
  },
  {
    country: "HR",
    currency: "HRK",
  },
  {
    country: "HT",
    currency: "G",
  },
  {
    country: "HU",
    currency: "HUF",
  },
  {
    country: "IC",
    currency: "\u20ac",
  },
  {
    country: "ID",
    currency: "Rp",
  },
  {
    country: "IE",
    currency: "\u20ac",
  },
  {
    country: "IL",
    currency: "\u20aa",
  },
  {
    country: "IM",
    currency: "\u00a3",
  },
  {
    country: "IN",
    currency: "\u20b9",
  },
  {
    country: "IO",
    currency: "US$",
  },
  {
    country: "IQ",
    currency: "IQD",
  },
  {
    country: "IR",
    currency: "IRR",
  },
  {
    country: "IS",
    currency: "ISK",
  },
  {
    country: "IT",
    currency: "\u20ac",
  },
  {
    country: "JE",
    currency: "\u00a3",
  },
  {
    country: "JM",
    currency: "$",
  },
  {
    country: "JO",
    currency: "\u062f.\u0623.\u200f",
  },
  {
    country: "JP",
    currency: "\u00a5",
  },
  {
    country: "KE",
    currency: "Ksh",
  },
  {
    country: "KG",
    currency: "\u0441\u043e\u043c",
  },
  {
    country: "KH",
    currency: "\u17db",
  },
  {
    country: "KI",
    currency: "$",
  },
  {
    country: "KM",
    currency: "CF",
  },
  {
    country: "KN",
    currency: "$",
  },
  {
    country: "KP",
    currency: "KPW",
  },
  {
    country: "KR",
    currency: "\u20a9",
  },
  {
    country: "KW",
    currency: "\u062f.\u0643.\u200f",
  },
  {
    country: "KY",
    currency: "$",
  },
  {
    country: "KZ",
    currency: "\u20b8",
  },
  {
    country: "LA",
    currency: "\u20ad",
  },
  {
    country: "LB",
    currency: "\u0644.\u0644.\u200f",
  },
  {
    country: "LC",
    currency: "$",
  },
  {
    country: "LI",
    currency: "CHF",
  },
  {
    country: "LK",
    currency: "Rs.",
  },
  {
    country: "LR",
    currency: "$",
  },
  {
    country: "LS",
    currency: "R",
  },
  {
    country: "LT",
    currency: "\u20ac",
  },
  {
    country: "LU",
    currency: "\u20ac",
  },
  {
    country: "LV",
    currency: "\u20ac",
  },
  {
    country: "LY",
    currency: "\u062f.\u0644.\u200f",
  },
  {
    country: "MA",
    currency: "MAD",
  },
  {
    country: "MC",
    currency: "\u20ac",
  },
  {
    country: "MD",
    currency: "L",
  },
  {
    country: "ME",
    currency: "\u20ac",
  },
  {
    country: "MF",
    currency: "\u20ac",
  },
  {
    country: "MG",
    currency: "Ar",
  },
  {
    country: "MH",
    currency: "$",
  },
  {
    country: "MK",
    currency: "den",
  },
  {
    country: "ML",
    currency: "CFA",
  },
  {
    country: "MM",
    currency: "K",
  },
  {
    country: "MN",
    currency: "\u20ae",
  },
  {
    country: "MO",
    currency: "MOP$",
  },
  {
    country: "MP",
    currency: "$",
  },
  {
    country: "MQ",
    currency: "\u20ac",
  },
  {
    country: "MR",
    currency: "UM",
  },
  {
    country: "MS",
    currency: "$",
  },
  {
    country: "MT",
    currency: "\u20ac",
  },
  {
    country: "MU",
    currency: "Rs",
  },
  {
    country: "MV",
    currency: "MVR",
  },
  {
    country: "MW",
    currency: "MK",
  },
  {
    country: "MX",
    currency: "$",
  },
  {
    country: "MY",
    currency: "RM",
  },
  {
    country: "MZ",
    currency: "MTn",
  },
  {
    country: "NA",
    currency: "$",
  },
  {
    country: "NC",
    currency: "FCFP",
  },
  {
    country: "NE",
    currency: "CFA",
  },
  {
    country: "NF",
    currency: "$",
  },
  {
    country: "NG",
    currency: "\u20a6",
  },
  {
    country: "NI",
    currency: "C$",
  },
  {
    country: "NL",
    currency: "\u20ac",
  },
  {
    country: "NO",
    currency: "kr",
  },
  {
    country: "NP",
    currency: "\u0928\u0947\u0930\u0942",
  },
  {
    country: "NR",
    currency: "$",
  },
  {
    country: "NU",
    currency: "$",
  },
  {
    country: "NZ",
    currency: "$",
  },
  {
    country: "OM",
    currency: "\u0631.\u0639.\u200f",
  },
  {
    country: "PA",
    currency: "B/.",
  },
  {
    country: "PE",
    currency: "S/.",
  },
  {
    country: "PF",
    currency: "FCFP",
  },
  {
    country: "PG",
    currency: "K",
  },
  {
    country: "PH",
    currency: "\u20b1",
  },
  {
    country: "PK",
    currency: "Rs",
  },
  {
    country: "PL",
    currency: "PLN",
  },
  {
    country: "PM",
    currency: "\u20ac",
  },
  {
    country: "PN",
    currency: "$",
  },
  {
    country: "PR",
    currency: "$",
  },
  {
    country: "PS",
    currency: "\u20aa",
  },
  {
    country: "PT",
    currency: "\u20ac",
  },
  {
    country: "PW",
    currency: "US$",
  },
  {
    country: "PY",
    currency: "Gs.",
  },
  {
    country: "QA",
    currency: "\u0631.\u0642.\u200f",
  },
  {
    country: "RE",
    currency: "\u20ac",
  },
  {
    country: "RO",
    currency: "RON",
  },
  {
    country: "RS",
    currency: "RSD",
  },
  {
    country: "RU",
    currency: "RUB",
  },
  {
    country: "RW",
    currency: "RF",
  },
  {
    country: "SA",
    currency: "\u0631.\u0633.\u200f",
  },
  {
    country: "SB",
    currency: "$",
  },
  {
    country: "SC",
    currency: "SR",
  },
  {
    country: "SD",
    currency: "SDG",
  },
  {
    country: "SE",
    currency: "kr",
  },
  {
    country: "SG",
    currency: "$",
  },
  {
    country: "SH",
    currency: "\u00a3",
  },
  {
    country: "SI",
    currency: "\u20ac",
  },
  {
    country: "SJ",
    currency: "kr",
  },
  {
    country: "SK",
    currency: "\u20ac",
  },
  {
    country: "SL",
    currency: "Le",
  },
  {
    country: "SM",
    currency: "\u20ac",
  },
  {
    country: "SN",
    currency: "CFA",
  },
  {
    country: "SO",
    currency: "S",
  },
  {
    country: "SR",
    currency: "$",
  },
  {
    country: "SS",
    currency: "\u00a3",
  },
  {
    country: "ST",
    currency: "Db",
  },
  {
    country: "SV",
    currency: "$",
  },
  {
    country: "SX",
    currency: "NAf.",
  },
  {
    country: "SY",
    currency: "\u0644.\u0633.\u200f",
  },
  {
    country: "SZ",
    currency: "E",
  },
  {
    country: "TC",
    currency: "US$",
  },
  {
    country: "TD",
    currency: "FCFA",
  },
  {
    country: "TG",
    currency: "CFA",
  },
  {
    country: "TH",
    currency: "THB",
  },
  {
    country: "TJ",
    currency: "\u0441\u043e\u043c",
  },
  {
    country: "TK",
    currency: "$",
  },
  {
    country: "TL",
    currency: "US$",
  },
  {
    country: "TM",
    currency: "TMT",
  },
  {
    country: "TN",
    currency: "\u062f.\u062a.\u200f",
  },
  {
    country: "TO",
    currency: "T$",
  },
  {
    country: "TR",
    currency: "TRY",
  },
  {
    country: "TT",
    currency: "$",
  },
  {
    country: "TV",
    currency: "$",
  },
  {
    country: "TW",
    currency: "NT$",
  },
  {
    country: "TZ",
    currency: "TSh",
  },
  {
    country: "UA",
    currency: "\u20b4",
  },
  {
    country: "UG",
    currency: "USh",
  },
  {
    country: "UM",
    currency: "$",
  },
  {
    country: "US",
    currency: "$",
  },
  {
    country: "UY",
    currency: "$",
  },
  {
    country: "UZ",
    currency: "so\u02bbm",
  },
  {
    country: "VC",
    currency: "$",
  },
  {
    country: "VE",
    currency: "Bs.",
  },
  {
    country: "VG",
    currency: "US$",
  },
  {
    country: "VI",
    currency: "$",
  },
  {
    country: "VN",
    currency: "\u20ab",
  },
  {
    country: "VU",
    currency: "VT",
  },
  {
    country: "WF",
    currency: "FCFP",
  },
  {
    country: "WS",
    currency: "WS$",
  },
  {
    country: "XK",
    currency: "\u20ac",
  },
  {
    country: "YE",
    currency: "\u0631.\u064a.\u200f",
  },
  {
    country: "YT",
    currency: "\u20ac",
  },
  {
    country: "ZA",
    currency: "R",
  },
  {
    country: "ZM",
    currency: "K",
  },
  {
    country: "ZW",
    currency: "US$",
  },
];
