import { FamilyButton } from "./ui/family-button";
import { Profile } from "iconsax-react";
import { Wallet } from "iconsax-react";
import { Power } from "lucide-react";
import { Link } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { getAuth, signOut } from "firebase/auth";
import { useDispatch } from "react-redux";
import { setAppLoading, setUser } from "@/redux/reducers/auth/auth.slice";
import app from "@/lib/config/firebase.config";
import { toast } from "sonner";

export const Navbar = () => {
  const auth = getAuth(app);
  const dispatch = useDispatch();
  const location = useLocation();

  const handleSignout = async () => {
    dispatch(setAppLoading(true));
    signOut(auth)
      .then(() => {
        dispatch({ type: "USER_LOGOUT" });
        dispatch(setUser(null));
        sessionStorage.clear();
        toast.info("User signed out");
      })
      .catch((error) => console.error(error))
      .finally(() => dispatch(setAppLoading(false)));
  };

  return (
    <div className="absolute bottom-4 right-4 ">
      <FamilyButton>
        <div className="p-4 flex flex-col gap-4">
          {location.pathname != "/account" && (
            <Link to="/account">
              <div className="rounded-full bg-[#FFCA00] p-2">
                <Profile size={36} />
              </div>
            </Link>
          )}
          {location.pathname != "/account/wallet" && (
            <Link to="/account/wallet">
              <div className="rounded-full bg-[#35ac31] p-2">
                <Wallet size={36} />
              </div>
            </Link>
          )}
          <hr />
          <div
            onClick={handleSignout}
            className="rounded-full bg-[#c3372a] p-2"
          >
            <Power size={36} />
          </div>
        </div>
      </FamilyButton>
    </div>
  );
};
