import SignoutButton from "./signout-button";
import WalletButton from "./wallet-button";

export const ButtonGroup = () => {
  return (
    <div className="fixed p-5 bottom-8 right-8 flex space-x-4 z-10">
      <WalletButton />
      <SignoutButton />
    </div>
  );
};
