import { Wrapper } from "@/components/wrapper";
import { useState, useEffect } from "react";
import { useSearchParams, useNavigate } from "react-router-dom";
import { motion } from "framer-motion";
import { CheckCircle, Copy } from "lucide-react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { setIsBalanceTopUp } from "@/redux/reducers/charger/charger.slice";

const WalletTopUp = () => {
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();
  const [timer, setTimer] = useState(5);
  const [copied, setCopied] = useState(false);
  const navigate = useNavigate();

  const { currency } = useSelector((state) => state.wallet);
  const { isBalanceTopUp } = useSelector((state) => state.charger);

  const status = searchParams.get("status");
  const transactionId = searchParams.get("transaction_id");
  const totalAmount = searchParams.get("total_amount");

  const handleCopy = () => {
    navigator.clipboard.writeText(transactionId);
    setCopied(true);
    setTimeout(() => setCopied(false), 2000);
  };

  useEffect(() => {
    const interval = setInterval(() => {
      setTimer((prev) => prev - 1);
      if (timer <= 1) {
        clearInterval(interval);
        if (isBalanceTopUp) {
          dispatch(setIsBalanceTopUp(false));
          navigate("/start-charger");
        } else {
          navigate("/account/wallet");
        }
      }
    }, 1000);

    return () => clearInterval(interval);
  }, [dispatch, isBalanceTopUp, navigate, timer]);

  return (
    <div className="w-full">
      <Wrapper className="w-full ">
        <div className="flex items-center justify-center pt-10">
          <motion.div
            initial={{ scale: 0.9, opacity: 0 }}
            animate={{ scale: 1, opacity: 1 }}
            transition={{ duration: 0.3 }}
            className="w-full max-w-md p-6 bg-[#CECECE30] rounded-xl shadow-xl"
          >
            <div className="text-center">
              <motion.h2
                className="text-3xl font-bold text-white mb-2 flex items-center justify-center"
                initial={{ y: -20, opacity: 0 }}
                animate={{ y: 0, opacity: 1 }}
                transition={{ delay: 0.2, duration: 0.5 }}
              >
                Topup {status}
                <motion.span
                  initial={{ scale: 0 }}
                  animate={{ scale: 1 }}
                  transition={{
                    delay: 0.5,
                    type: "spring",
                    stiffness: 500,
                    damping: 15,
                  }}
                >
                  <CheckCircle className="ml-2 text-green-400 w-8 h-8" />
                </motion.span>
              </motion.h2>
              <p className="text-gray-400 mb-6">Successfully added:</p>
            </div>

            <motion.div
              className="relative w-48 h-48 mx-auto mb-6"
              initial={{ rotate: -90 }}
              animate={{ rotate: 0 }}
              transition={{ delay: 0.3, duration: 0.5 }}
            >
              <svg className="w-full h-full" viewBox="0 0 100 100">
                <circle
                  cx="50"
                  cy="50"
                  r="45"
                  fill="none"
                  stroke="#4B5563"
                  strokeWidth="8"
                />
                <motion.circle
                  cx="50"
                  cy="50"
                  r="45"
                  fill="none"
                  stroke="#10B981"
                  strokeWidth="8"
                  strokeLinecap="round"
                  initial={{ pathLength: 0 }}
                  animate={{ pathLength: 1 }}
                  transition={{ delay: 0.5, duration: 1, ease: "easeInOut" }}
                />
              </svg>
              <div className="absolute inset-0 flex items-center justify-center">
                <motion.span
                  className="text-4xl font-bold text-white"
                  initial={{ scale: 0 }}
                  animate={{ scale: 1 }}
                  transition={{ delay: 0.8, type: "spring", stiffness: 200 }}
                >
                  {totalAmount / 100}
                  {currency}
                </motion.span>
              </div>
            </motion.div>

            <div className="text-center mb-4">
              <p className="text-sm text-gray-400 mb-1">
                Transaction Id: {transactionId}
              </p>
              <div className="flex items-center justify-center space-x-2">
                <code className="text-xs bg-gray-700 px-2 py-1 rounded">
                  {copied ? "Copied!" : transactionId}
                </code>
                <button
                  onClick={handleCopy}
                  className="text-gray-400 hover:text-white transition-colors"
                >
                  <Copy size={16} />
                </button>
              </div>
            </div>

            <motion.div
              className="text-center text-white text-lg font-medium"
              animate={{ opacity: [1, 0.5, 1] }}
              transition={{
                repeat: Infinity,
                duration: 2,
                ease: "easeInOut",
              }}
            >
              Redirecting to wallet in {timer} seconds
            </motion.div>
          </motion.div>
        </div>
      </Wrapper>
    </div>
  );
};

export default WalletTopUp;
