import { PlusCircle } from "lucide-react";
import { Button } from "../ui/button";

export const AddFunds = ({ onClick }) => {
  return (
    <Button
      onClick={onClick}
      style={{
        background: "rgba(206, 206, 206, 0.18)",
        boxShadow: "0px 0px 20px 0px #FED127 inset",
        backdropFilter: "blur(10px)",
      }}
      className="justify-between w-full rounded-2xl"
    >
      <PlusCircle />
      Add funds
    </Button>
  );
};
