import { useState } from "react";
import { Skeleton } from "@/components/ui/skeleton";
import { useGetWalletHistoryQuery } from "@/redux/reducers/wallet/wallet.api";
import WalletHistoryItem from "./wallet-history-item";
import { Button } from "../ui/button";

const DepositList = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const {
    data: walletHistoryData,
    isLoading: walletHistoryIsLoading,
    isError: walletHistoryIsError,
    isSuccess: walletHistoryIsSuccess,
    isFetching: walletHistoryIsFetching,
  } = useGetWalletHistoryQuery({
    page: currentPage,
  });

  return (
    <div>
      <h2 className="text-xl font-light text-left pb-5">Wallet History</h2>
      {walletHistoryIsLoading ? (
        <div className="space-y-4">
          <Skeleton className="rounded-lg h-[45px]" />
          <Skeleton className="rounded-lg h-[45px]" />
          <Skeleton className="rounded-lg h-[45px]" />
        </div>
      ) : walletHistoryIsError ? (
        <>Error</>
      ) : walletHistoryData && walletHistoryIsSuccess ? (
        <div className="space-y-4 max-h-[300px] overflow-y-auto">
          {walletHistoryData.results.length > 0 ? (
            walletHistoryData.results.map((item, index) => (
              <WalletHistoryItem
                key={index}
                transactionTime={item.transaction_time}
                amount={item.amount}
                status={item.status}
                transactionType={item.transaction_type}
              />
            ))
          ) : (
            <div className="text-center p-4 bg-[#cecece09] rounded-2xl text-[#FFFFFFCC]">
              No Transactions Yet
            </div>
          )}

          {walletHistoryData.next && (
            <div className="text-center">
              <Button
                disabled={walletHistoryIsLoading || walletHistoryIsFetching}
                onClick={() => {
                  setCurrentPage(currentPage + 1);
                }}
              >
                Load More
              </Button>
            </div>
          )}
        </div>
      ) : (
        <div className="text-center p-4 bg-[#cecece09] rounded-2xl text-[#FFFFFFCC]">
          No Transactions Yet
        </div>
      )}
    </div>
  );
};

export default DepositList;
