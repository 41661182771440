import { currencyList } from "@/lib/constants/currency-list";
import PhoneNumber from "libphonenumber-js";

export const extractCurrencySymbol = (countryCode) => {
  try {
    // Find the currency object in the currencyList array that matches the given country code
    const currencyObject = currencyList.find(
      (currency) => currency.country === countryCode
    );

    // Return the currency symbol if found
    if (currencyObject) {

      // !Ensure to remove this comment once the decision has been made to allow various currencies
      // return currencyObject.currency;
      return "रु";
    } else {
      console.error("Currency symbol not found for country code:", countryCode);
      return null;
    }
  } catch (error) {
    console.error("Error extracting currency symbol:", error);
    return null;
  }
};

export const extractCountryCode = (phoneNumber) => {
  try {
    // Parse the phone number
    const parsedPhoneNumber = PhoneNumber(phoneNumber);

    // Get the country code
    if (parsedPhoneNumber) {
      const countryCode = parsedPhoneNumber.country;
      return countryCode;
    }
  } catch (error) {
    console.error("Error parsing phone number:", error);
    return null;
  }
};

export const defaultMaskOptions = {
  prefix: "",
  suffix: "",
  includeThousandsSeparator: true,
  thousandsSeparatorSymbol: ",",
  allowDecimal: true,
  decimalSymbol: ".",
  decimalLimit: 2,
  integerLimit: 7,
  allowNegative: false,
  allowLeadingZeroes: false,
};

export const convertCurrencyToNumber = (currency) => {
  const plainNumberString = currency.replace(/[^0-9.-]+/g, "");
  const plainNumber = Number(plainNumberString);
  return plainNumber;
};
