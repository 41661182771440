export const AccountBox = () => {
  return (
    <div className="  p-4  rounded-2xl bg-[#CECECE30] flex flex-col items-center gap-6 ">
      <div className="space-y-2 flex flex-col justify-center items-center">
        <p className="text-sm font-bold ">Hi there, </p>
        <p className="text-sm font-light ">This is</p>
        <p className="text-lg font-bold text-[#FED127E8]/90 ">
          Account management
        </p>
      </div>
      <div>
        <img src="/images/account.png" alt="account" />
      </div>
      <div className="text-[10px]  font-light">
        Attention: This page is for account management only. To charge your EV,
        please scan the QR code located on our charging stations.
      </div>
    </div>
  );
};
