import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormMessage,
} from "@/components/ui/form";
import MaskedInput from "react-text-mask";
import createNumberMask from "text-mask-addons/dist/createNumberMask";
import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import { z } from "zod";
import { toast } from "sonner";
import { useMakePaymentMutation } from "@/redux/reducers/wallet/wallet.api";
import { useSelector } from "react-redux";
import { Button } from "../ui/button";
import { cn } from "@/lib/utils/cn";
import { defaultMaskOptions } from "@/lib/utils/currency";

const formSchema = z.object({
  amount: z.coerce.string({
    required_error: "Please enter an amount.",
  }),
});

const defaultAmountOptions = ["50", "100", "250", "500"];

function convertCurrencyStringToInt(currencyString) {
  // Remove any non-digit characters
  let numericString = currencyString.replace(/[^\d]/g, "");

  // Convert the resulting string to an integer
  let result = parseInt(numericString, 10);

  return result;
}

export const Funding = ({ onClose }) => {
  const websiteOrigin = window.location.origin;
  const form = useForm({
    resolver: zodResolver(formSchema),
  });

  const { currency } = useSelector((state) => state.wallet);

  const currencyMask = createNumberMask(defaultMaskOptions);

  const [makePayment, { isLoading: makePaymentIsLoading }] =
    useMakePaymentMutation();

  const handleSubmit = (data) => {
    const amount = convertCurrencyStringToInt(data.amount);

    // const userName = userProfile.first_name + userProfile.last_name;
    const userName = "Test User"; //no name field for now

    if (amount <= 10 || amount >= 1001) {
      form.setError("amount", {
        type: "manual",
        message: "Amount must be between 10 and 1001.",
      });
      return;
    }
    const body = {
      return_url: `${websiteOrigin}/account/wallet/success`,
      website_url: `${websiteOrigin}/account/wallet`,
      amount: amount * 100, // khalti takes whatever you give and divides by 100, so we multiply it here to offset that
      purchase_order_id: "stringstri",
      purchase_order_name: "string",
      customer_info: { name: userName },
      product_details: null,
    };

    makePayment(body)
      .unwrap()
      .then((response) => {
        window.location = response.data.payment_url;
        toast.success(response.message);
      })
      .catch((error) => {
        console.error(error);
        try {
          toast.error(error.data.error_message[0].msg);
        } catch (error) {
          console.error(error);
          toast.error("Error making payment, please try again");
        }
      });
  };

  return (
    <div>
      <div className="space-y-4">
        <p className="text-center">Please enter the amount</p>
        <Form {...form}>
          <form
            onSubmit={form.handleSubmit(handleSubmit)}
            className="flex flex-col items-center space-y-3"
          >
            <FormField
              control={form.control}
              name="amount"
              render={({ field }) => (
                <FormItem>
                  <div className="flex items-center justify-center">
                    <Button
                      style={{
                        borderRadius: " 8px 0px 0px 8px",
                        borderTop: "1px solid rgba(208, 213, 221, 0.45)",
                        borderBottom: "1px solid rgba(208, 213, 221, 0.45)",
                        borderLeft: "1px solid rgba(208, 213, 221, 0.45)",
                        background: "rgba(255, 255, 255, 0.45)",
                        boxShadow: "0px 0px 20px 0px #FED127 inset",
                      }}
                      className="w-[50px] rounded-r-none border-none"
                    >
                      {currency}
                    </Button>
                    <FormControl>
                      <MaskedInput
                        style={{
                          borderRadius: "0px 8px 8px 0px",
                          border: "1px solid rgba(208, 213, 221, 0.45)",
                          background: "rgba(255, 255, 255, 0.45)",
                          boxShadow: "0px 0px 20px 0px #FED127 inset",
                        }}
                        className={cn(
                          "flex h-10 w-full rounded-md border border-gray-200 bg-white px-3 py-2 text-sm  file:border-0 file:bg-transparent file:text-sm file:font-medium placeholder:text-gray-500  disabled:cursor-not-allowed disabled:opacity-50 dark:border-gray-800 dark:bg-gray-950 dark:ring-offset-gray-950 dark:placeholder:text-gray-400 dark:focus-visible:ring-gray-300",
                          "text-white"
                        )}
                        placeholder="0.00"
                        inputMode="numeric"
                        mask={currencyMask}
                        {...field}
                      />
                    </FormControl>
                  </div>
                  <FormMessage />
                </FormItem>
              )}
            />

            <div className="space-x-2">
              {defaultAmountOptions.map((option, index) => (
                <Button
                  key={index}
                  type="button"
                  onClick={() => {
                    form.setValue("amount", convertCurrencyStringToInt(option));
                  }}
                  className="rounded-full bg-[#FFFFFF33] filter px-3 w-max h-auto py-[2px]"
                >
                  {option}
                </Button>
              ))}
            </div>
            <div className="flex flex-col gap-3 my-8 ">
              <Button
                type="submit"
                disabled={makePaymentIsLoading}
                style={{
                  borderRadius: "15px",
                  background: "rgba(206, 206, 206, 0.18)",
                  boxShadow: "0px 0px 20px 0px #FED127 inset",
                  backdropFilter: "blur(10px)",
                }}
                className="text-[#FFFFFFCC]"
              >
                Add Funds
              </Button>
              <Button
                type="button"
                onClick={onClose}
                style={{
                  borderRadius: "15px",
                  background: "rgba(206, 206, 206, 0.18)",
                  boxShadow: "0px 0px 20px 0px #FED127 inset",
                  backdropFilter: "blur(10px)",
                }}
                className=" text-[#FFFFFFCC]"
              >
                Back
              </Button>
            </div>
          </form>
        </Form>
      </div>
    </div>
  );
};
