import { api } from "@/lib/utils/api";

export const walletApi = api.injectEndpoints({
  endpoints: (builder) => ({
    makePayment: builder.mutation({
      query: (body) => ({
        url: "api/v1/drive/payments/khalti-accept-payment/",
        method: "POST",
        body: body,
      }),
    }),
    getPayments: builder.query({
      query: ({ page = 1 }) => `api/v1/drive/payments/?page=${page}`,
      serializeQueryArgs: ({ endpointName }) => {
        return endpointName;
      },
      merge: (currentCache, newItems) => {
        if (!newItems.previous) {
          currentCache.results = newItems.results;
          currentCache.next = newItems.next;
          currentCache.previous = newItems.previous;
          return;
        }
        currentCache.results = [...currentCache.results, ...newItems.results];
        currentCache.next = newItems.next;
        currentCache.previous = newItems.previous;
      },
      forceRefetch({ currentArg, previousArg }) {
        return currentArg !== previousArg;
      },
    }),
    getWallet: builder.query({
      query: () => "api/v1/drive/wallets/",
    }),
    getWalletHistory: builder.query({
      query: ({ page = 1 }) =>
        `api/v1/drive/wallets/transactions/?page=${page}`,
      serializeQueryArgs: ({ endpointName }) => {
        return endpointName;
      },
      merge: (currentCache, newItems) => {
        if (!newItems.previous) {
          currentCache.results = newItems.results;
          currentCache.next = newItems.next;
          currentCache.previous = newItems.previous;
          return;
        }
        currentCache.results = [...currentCache.results, ...newItems.results];
        currentCache.next = newItems.next;
        currentCache.previous = newItems.previous;
      },
      forceRefetch({ currentArg, previousArg }) {
        return currentArg !== previousArg;
      },
    }),
  }),
  overrideExisting: false,
});

export const {
  useMakePaymentMutation,
  useGetPaymentsQuery,
  useGetWalletQuery,
  useGetWalletHistoryQuery,
} = walletApi;
