import { Dialog, DialogContent, DialogTitle } from "@/components/ui/dialog";
import { Button, buttonVariants } from "../ui/button";
import { ArrowRight } from "iconsax-react";
import { Link } from "react-router-dom";
import { cn } from "@/lib/utils/cn";

export const NewUser = ({ isOpen, onClose }) => {
  return (
    <Dialog open={isOpen} modal>
      <DialogContent onClose={onClose} className="max-w-xs border-[#333]">
        <div className="flex items-center flex-col gap-4">
          <div>
            <img src="/images/account.png" height={100} width={100} />
          </div>
          <DialogTitle className="text-center text-xl font-semibold">
            You have created an account.
          </DialogTitle>
          <p>
            You have successfully created a new account, please update your
            profile.
          </p>
          <div className="flex flex-col gap-2">
            <Link
              to="/account/?edit-account=true"
              onClick={onClose}
              className={cn(
                buttonVariants({ variant: "secondary" }),
                "w-full gap-3"
              )}
            >
              Update Account <ArrowRight />
            </Link>
            <Button onClick={onClose}>Cancel</Button>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
};
