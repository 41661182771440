import { Navigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useEffect } from "react";
import { setIsAuthenticated, setUser } from "@/redux/reducers/auth/auth.slice";
import { useSelector } from "react-redux";
import app from "@/lib/config/firebase.config";
import { getAuth, getIdToken, onAuthStateChanged } from "firebase/auth";
import { Suspense } from "react";
import { LoaderPage } from "@/components/loader-page";

export const AuthRoutes = ({ children }) => {
  const dispatch = useDispatch();

  const { isAuthenticated } = useSelector((state) => state.auth);
  const { isCharger } = useSelector((state) => state.charger);
  const auth = getAuth(app);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      if (user) {
        dispatch(setUser(user));
        dispatch(setIsAuthenticated(true));
        const idToken = await getIdToken(auth.currentUser);
        sessionStorage.setItem("access::token", idToken);
      } else {
        sessionStorage.clear();
        dispatch(setIsAuthenticated(false));
      }
    });
    return () => {
      unsubscribe;
    };
  }, [auth, dispatch]);


  if (!isAuthenticated) {
    return <Suspense fallback={<LoaderPage />}>{children}</Suspense>;
  } else if (isCharger) {
    return <Navigate to="/plugin-charger" />;
  } else {
    return <Navigate to="/account" />;
  }
};
