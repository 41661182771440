import { api } from "@/lib/utils/api";
import { setChargePointInfo } from "./charger.slice";

export const chargerApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getChargerDetail: builder.query({
      query: (chargePointId) => {
        return `/api/v1/core_backend/drive-user-charge-point-by-id/?charge_point_id=${chargePointId}`;
      },
      onQueryStarted: async (arg, { dispatch, queryFulfilled }) => {
        try {
          const { data } = await queryFulfilled;
          dispatch(setChargePointInfo(data.data));
        } catch (error) {
          console.error("error", error);
        }
      },
    }),
    getChargersWithinDistance: builder.query({
      query: (values) => {
        return `api/v1/core_backend/driver-user-nearby-chargers/?latitude=${values.latitude}&longitude=${values.longitude}&max_distance=${values.max_distance}`;
      },
    }),
    remoteStartTransaction: builder.mutation({
      query: (values) => ({
        url: `/core_central_ocpp/remote_start_transaction/`,
        method: "POST",
        body: values,
      }),
    }),
    remoteStopTransaction: builder.mutation({
      query: (values) => ({
        url: `/core_central_ocpp/remote_stop_transaction/`,
        method: "POST",
        body: values,
      }),
    }),
    getPastChargingSessions: builder.query({
      query: ({ page }) =>
        `/api/v1/drive/chargers/charging-sessions/?page=${page}`,

      serializeQueryArgs: ({ endpointName }) => {
        return endpointName;
      },

      merge: (currentCache, newItems) => {
        if (!newItems.previous) {
          currentCache.results = newItems.results;
          currentCache.next = newItems.next;
          currentCache.previous = newItems.previous;
          return;
        }
        currentCache.results = [...currentCache.results, ...newItems.results];
        currentCache.next = newItems.next;
        currentCache.previous = newItems.previous;
      },

      // Refetch when the page arg changes
      forceRefetch({ currentArg, previousArg }) {
        return currentArg !== previousArg;
      },
    }),
  }),
});

export const {
  useGetChargerDetailQuery,
  useLazyGetChargerDetailQuery,
  useRemoteStartTransactionMutation,
  useRemoteStopTransactionMutation,
  useGetPastChargingSessionsQuery,
  useGetChargersWithinDistanceQuery,
  useLazyGetChargersWithinDistanceQuery,
} = chargerApi;
