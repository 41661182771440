import { ChevronRight } from "lucide-react";
import { buttonVariants } from "./ui/button";
import { Link } from "react-router-dom";
import { cn } from "@/lib/utils/cn";
import { useSelector } from "react-redux";
import { useGetWalletQuery } from "@/redux/reducers/wallet/wallet.api";
import { Skeleton } from "@/components/ui/skeleton";

export const PaymentBox = () => {
  const { currency } = useSelector((state) => state.wallet);

  const { data, isLoading } = useGetWalletQuery(undefined);

  return isLoading ? (
    <Skeleton className="rounded-lg h-[45px]" />
  ) : (
    data && (
      <Link
        to="/account/wallet"
        style={{
          borderRadius: "15px",
          background:
            "linear-gradient(120deg, rgba(254, 209, 39, 0.00) 7.79%, #FFCA00 208.75%), #cecece2d",

          backdropFilter: "blur(10px)",
        }}
        className={cn(
          buttonVariants(),
          "rounded-2xl relative p-3 overflow-hidden w-full"
        )}
      >
        <div
          style={{
            background: "url(/images/logo-payment.png)",
          }}
          className="h-[102px] w-[102px] !bg-contain absolute !bg-center -left-3 -top-4 !opacity-20"
        />
        <div className=" w-full flex items-center gap-2 justify-end ">
          <span className="text-2xl text-white text-right font-semibold">
            {currency}
            {parseFloat(data.data?.balance).toLocaleString()}
          </span>
          <ChevronRight className=" animate-pulse" size={12} />
        </div>
      </Link>
    )
  );
};
