import React from "react";

import { formatDateString } from "@/lib/utils/date";
import { useSelector } from "react-redux";

const WalletHistoryItem = ({
  transactionTime,
  amount,
  status,
  transactionType,
}) => {
  const { currency } = useSelector((state) => state.wallet);

  return (
    <div className="w-full px-5 py-3 bg-[#CECECE2E] rounded-2xl flex items-center justify-between">
      <p>{formatDateString(transactionTime)}</p>

      <p
        className={`${
          transactionType.toLowerCase() === "debit"
            ? "text-red-400"
            : transactionType.toLowerCase() === "credit"
            ? "text-green-400"
            : ""
        }`}
      >
        <span>
          {transactionType.toLowerCase() === "debit"
            ? "-"
            : transactionType.toLowerCase() === "credit"
            ? "+"
            : ""}
        </span>{" "}
        {currency}
        {amount}
      </p>
    </div>
  );
};

export default WalletHistoryItem;
