import { useState } from "react";
import { getAuth, signOut } from "firebase/auth";
import { useSelector } from "react-redux";
import app from "@/lib/config/firebase.config";
import { toast } from "sonner";
import { Power } from "lucide-react";

const SignoutButton = () => {
  const auth = getAuth(app);
  const { user } = useSelector((state) => state.auth);

  const [loading, setLoading] = useState(false);

  const handleSignout = async () => {
    setLoading(true);
    signOut(auth)
      .then(() => toast.info("User signed out"))
      .catch((error) => console.error(error))
      .finally(() => setLoading(false));
  };

  if (user) {
    return (
      <button
        disabled={loading}
        className="bg-red-800 hover:bg-red-900 disabled:bg-gray-500 p-5 rounded-full"
        onClick={handleSignout}
      >
        <Power />
      </button>
    );
  }
};

export default SignoutButton;
