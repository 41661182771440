import { Navigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useEffect } from "react";
import { setIsAuthenticated, setUser } from "@/redux/reducers/auth/auth.slice";
import { useSelector } from "react-redux";
import app from "@/lib/config/firebase.config";
import { getAuth, getIdToken, onAuthStateChanged } from "firebase/auth";
import { Suspense } from "react";
import {
  extractCountryCode,
  extractCurrencySymbol,
} from "@/lib/utils/currency";
import { setCurrency } from "@/redux/reducers/wallet/wallet.slice";
import { LoaderPage } from "@/components/loader-page";

export const PrivateRoutes = ({ children }) => {
  const dispatch = useDispatch();

  const { isAuthenticated } = useSelector((state) => state.auth);

  const auth = getAuth(app);
  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      if (user) {
        dispatch(setUser(user));
        dispatch(setIsAuthenticated(true));
        const idToken = await getIdToken(auth.currentUser);
        sessionStorage.setItem("access::token", idToken);
      } else {
        dispatch({ type: "USER_LOGOUT" });
        dispatch(setIsAuthenticated(false));
        sessionStorage.clear();
      }

      const countryCode = extractCountryCode(user?.phoneNumber);
      if (countryCode) {
        const currencySymbol = extractCurrencySymbol(countryCode);
        dispatch(setCurrency(currencySymbol));
      } else {
        dispatch(setCurrency("$"));
      }
    });

    return () => {
      unsubscribe;
    };
  }, [dispatch]);

  if (isAuthenticated) {
    return <Suspense fallback={<LoaderPage />}>{children}</Suspense>;
  } else {
    return <Navigate to="/account/auth" />;
  }
};
