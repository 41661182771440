import { GoogleMap, MarkerF, useJsApiLoader } from "@react-google-maps/api";
import { MutatingDots } from "react-loader-spinner";

export const ChargerLocation = ({ lat, lng }) => {
  // Load the script asynchronously - Replace import useLoadScript with useJsApiLoader in your import from "@react-google-maps/api";

  const { isLoaded, loadError } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: import.meta.env.VITE_GOOGLE_API_KEY,
    mapIds: [import.meta.env.VITE_GOOGLE_MAP_ID],
    // Load the script asynchronously
  });

  if (loadError)
    return (
      <div className="rounded-[33px] h-[134px] bg-contain bg-no-repeat w-full flex items-center justify-center">
        <p className="text-sl font-semibold">Error loading maps</p>
      </div>
    );
  if (!isLoaded)
    return (
      <div className="rounded-[33px] h-[134px] bg-contain bg-no-repeat w-full flex items-center justify-center">
        <MutatingDots
          visible={true}
          height="100"
          width="100"
          color="#FFCA00"
          secondaryColor="#ffb300"
          radius="12.5"
          ariaLabel="mutating-dots-loading"
          wrapperStyle={{}}
          wrapperClass=""
        />
      </div>
    );

  const defaultCenter = {
    lat: lat,
    lng: lng,
  };

  const chargerLocations = [{ lat: lat, lng: lng }];

  return (
    <div style={{ boxShadow: "0px 0px 75.6px 0px #000 inset" }}>
      <div
        style={{
          boxShadow: "0px 0px 20px 0px #4d4d4d inset",
          borderRadius: "33px",
        }}
        className="overflow-hidden relative h-[130px] w-full "
      >
        <GoogleMap
          mapContainerStyle={{ height: "100%" }}
          zoom={10} // Adjust initial zoom level as needed
          center={defaultCenter}
          options={{
            mapId: import.meta.env.VITE_GOOGLE_MAP_ID,
            disableDefaultUI: true,
            gestureHandling: "none",
            zoomControl: false,
            draggable: false,
          }}
        >
          {chargerLocations.map((charger) => (
            <MarkerF
              key={`${charger.lat}-${charger.lng}`}
              position={charger}
              icon={{
                url: "/icons/pointer.png",
                origin: new window.google.maps.Point(0, 0),
                scaledSize: new window.google.maps.Size(12, 15),
                // labelOrigin: new window.google.maps.Point(25, -15),
              }}
            />
          ))}
        </GoogleMap>
      </div>
    </div>
  );
};
