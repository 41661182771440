import {
  InputOTP,
  InputOTPGroup,
  InputOTPSlot,
} from "@/components/ui/input-otp";
import { z } from "zod";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { useState } from "react";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormMessage,
} from "@/components/ui/form";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { Button } from "./ui/button";
import { ArrowRight } from "iconsax-react";
import app from "@/lib/config/firebase.config";
import { getAuth, RecaptchaVerifier } from "firebase/auth";
import { cn } from "@/lib/utils/cn";

const formSchema = z.object({
  pin: z.string().min(6, {
    message: "Your one-time password must be 6 characters.",
  }),
});

export const VerifyAuth = ({ onSubmit, resendOTP }) => {
  const [countdown, setCountdown] = useState(3);
  const { loading } = useSelector((state) => state.auth);
  const auth = getAuth(app);

  const form = useForm({
    resolver: zodResolver(formSchema),
    defaultValues: {
      pin: "",
    },
  });

  if (import.meta.env.VITE_ENV != "production") {
    auth.settings.appVerificationDisabledForTesting = true;
  }

  useEffect(() => {
    try {
      window.recaptchaVerifier = new RecaptchaVerifier(
        auth,
        "recaptcha-confirm",
        {
          size: "normal",
          callback: () => {
            // reCAPTCHA solved - allow resendOTP.
            resendOTP();
          },
          "expired-callback": () => {
            console.log("expired-callback");
          },
        }
      );
      window.recaptchaVerifier.render().catch((error) => {
        console.error("Error during RecaptchaVerifier render:", error);
      });
    } catch (e) {
      console.error("Error during RecaptchaVerifier:", e);
    }

    return () => {
      window.recaptchaVerifier.clear();
    };
    // do not attempt to pass in the resendOTP function as a dependency here. i'm not sure on why it breaks the app after user otp is passed in, but it works if you don't pass it.
  }, [auth]);

  useEffect(() => {
    const timer = setInterval(() => {
      if (countdown > 0) {
        setCountdown(countdown - 1);
      } else {
        clearInterval(timer);
      }
    }, 1000);

    return () => clearInterval(timer);
  }, [countdown]);

  return (
    <div className="flex flex-col items-center gap-4">
      <span>Please enter the verification code </span>
      <div className="w-[90%]  flex flex-col items-center gap-4">
        <hr className=" border-[#dedede93] w-full" />
        <Form {...form}>
          <form onSubmit={form.handleSubmit(onSubmit)} className=" space-y-6">
            <FormField
              control={form.control}
              name="pin"
              render={({ field }) => (
                <FormItem>
                  <FormControl>
                    <div className="flex gap-[2px]">
                      <InputOTP
                        onComplete={form.handleSubmit(onSubmit)}
                        className=""
                        maxLength={6}
                        disabled={loading}
                        {...field}
                      >
                        <InputOTPGroup className="rounded-none">
                          <InputOTPSlot
                            style={{
                              background: "rgba(255, 255, 255, 0.45)",
                              boxShadow:
                                "inset 0px -20px 20px -20px #FED127, inset 0px 20px 20px -20px #FED127, inset 20px 0px 20px -20px #FED127",
                            }}
                            className="text-sm border-none"
                            index={0}
                          />
                          <InputOTPSlot
                            style={{
                              background: "rgba(255, 255, 255, 0.45)",
                              boxShadow:
                                "inset 0px -20px 20px -20px #FED127, inset 0px 20px 20px -20px #FED127",
                            }}
                            className="text-sm border-none"
                            index={1}
                          />
                          <InputOTPSlot
                            style={{
                              background: "rgba(255, 255, 255, 0.45)",
                              boxShadow:
                                "inset 0px -20px 20px -20px #FED127, inset 0px 20px 20px -20px #FED127",
                            }}
                            className="text-sm border-none"
                            index={2}
                          />
                          <InputOTPSlot
                            style={{
                              background: "rgba(255, 255, 255, 0.45)",
                              boxShadow:
                                "inset 0px -20px 20px -20px #FED127, inset 0px 20px 20px -20px #FED127",
                            }}
                            className="text-sm border-none"
                            index={3}
                          />
                          <InputOTPSlot
                            style={{
                              background: "rgba(255, 255, 255, 0.45)",
                              boxShadow:
                                "inset 0px -20px 20px -20px #FED127, inset 0px 20px 20px -20px #FED127",
                            }}
                            className="text-sm border-none"
                            index={4}
                          />
                          <InputOTPSlot
                            style={{
                              background: "rgba(255, 255, 255, 0.45)",
                              boxShadow:
                                "inset 0px -20px 20px -20px #FED127, inset 0px 20px 20px -20px #FED127, inset -20px 0px 20px -20px #FED127",
                            }}
                            className="text-sm last:rounded-r-none border-none"
                            index={5}
                          />
                        </InputOTPGroup>
                      </InputOTP>
                      <div
                        className="  rounded-r-lg flex-[1]"
                        style={{
                          background: "rgba(255, 255, 255, 0.45)",
                          boxShadow: "0px 1px 2px 0px rgba(16, 24, 40, 0.05)",
                        }}
                      >
                        <Button
                          disabled={loading}
                          type="submit"
                          className=" bg-transparent w-full h-full border-0 rounded-l-none"
                          style={{
                            boxShadow: "  0px 0px 20px 0px #FED127 inset",
                          }}
                        >
                          <ArrowRight />
                        </Button>
                      </div>
                    </div>
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
          </form>
        </Form>
        <span className=" text-gray-500 text-sm whitespace-nowrap">
          Didn’t receive the code?{" "}
          {countdown == 0 ? (
            <Button
              onClick={resendOTP}
              variant="link"
              className="font-bold text-white p-0 "
            >
              Resend ({countdown})
            </Button>
          ) : (
            ` Resend (${countdown})`
          )}
        </span>
        <div
          id="recaptcha-confirm"
          className={cn(countdown == 0 ? "block" : "hidden")}
        ></div>
      </div>
    </div>
  );
};
