import { useSelector } from "react-redux";
import { useGetWalletQuery } from "@/redux/reducers/wallet/wallet.api";
import { Skeleton } from "@/components/ui/skeleton";

export const WalletCard = () => {
  const { currency } = useSelector((state) => state.wallet);

  const {
    data: walletData,
    isLoading: walletLoading,
    isError: isWalletError,
    isSuccess: isWalletSuccess,
  } = useGetWalletQuery();

  return walletLoading ? (
    <Skeleton className="rounded-lg h-[200px]" />
  ) : (
    isWalletSuccess && (
      <div
        style={{
          background:
            "linear-gradient(120deg, rgba(254, 209, 39, 0.00) 7.79%, #FFCA00 208.75%), rgba(206, 206, 206, 0.18)",

          backdropFilter: " blur(10px)",
        }}
        className=" rounded-2xl p-3 overflow-hidden h-[200px] relative"
      >
        <div
          style={{
            background: "url(/images/logo-payment.png)",
          }}
          className="h-[208px] w-[227px] !bg-contain absolute !bg-center -left-20 top-16 !opacity-20"
        />

        <div className="flex flex-col justify-between h-full items-end">
          <div className="text-[#FFFFFF47] text-2xl font-semibold">
            Drive Wallet
          </div>
          {walletLoading ? (
            <>loading</>
          ) : isWalletError ? (
            <>Error getting Balance, please retry</>
          ) : isWalletSuccess ? (
            walletData?.data && (
              <div className="text-2xl font-semibold">
                {currency || ""}
                {parseFloat(walletData.data?.balance).toLocaleString()}
              </div>
            )
          ) : null}
        </div>
      </div>
    )
  );
};
