import { ChargerBoxBusy } from "@/components/charger-boxes/charger-box-busy";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Wrapper } from "@/components/wrapper";
import { useEffect } from "react";
import { ChargerBoxesHeader } from "@/components/charger-boxes/charger-boxes-header";
import { ChargingComplete } from "@/components/charging-complete";
import { formatTime } from "@/lib/utils/date";
import { PaymentBox } from "@/components/payment-box";

export default function CompleteCharge() {

  const { meterValues, elapsedTime } = useSelector((state) => state.session);
  const { currency } = useSelector((state) => state.wallet);


  return (
    <Wrapper>
      <div className="flex flex-col gap-3 items-center">
        {/* <ChargerBoxBusy /> */}{" "}
        <div className=" p-4  rounded-2xl bg-[#CECECE30] flex flex-col items-center gap-1 w-full">
          <ChargerBoxesHeader status="free" />
        </div>
        <ChargingComplete />
        <div className=" p-4 rounded-[30px] bg-[#CECECE30] w-full  space-y-4">
          <PaymentBox />
          <div className="grid grid-cols-2 gap-4 items-start">
            <div className="flex items-start flex-col gap-1">
              <span className="text-sm font-light">Charging Rate</span>
              <div className=" text-2xl font-bold">
                {meterValues.charging_rate || 0} kW
              </div>
            </div>
            <div className="flex items-start flex-col gap-1">
              <span className="text-sm font-light">Time Elapsed</span>
              <div className=" text-2xl font-bold">
                {/* {formatTime(meterValues.duration) || "0:00"} */}

                {/* I have decided to replace the meter values cause due to seome reasons, the meter value 'duration' does not update itself; it defaults back to 0:00 */}
                {formatTime(elapsedTime) || "0:00"}
              </div>
            </div>{" "}
            <div className="flex items-start flex-col gap-1">
              <span className="text-sm font-light">Energy Consumed</span>
              <div className=" text-2xl font-bold">
                {meterValues.energy_consumed || 0} kWh
              </div>
            </div>{" "}
            <div className="flex items-start flex-col gap-1">
              <span className="text-sm font-light">Cost of Charging</span>
              <div className=" text-2xl font-bold">
                {currency} {meterValues.total_price || 0}
              </div>
            </div>
          </div>
        </div>
      </div>
    </Wrapper>
  );
}
