import { api } from "@/lib/utils/api";

export const authApi = api.injectEndpoints({
  tagTypes: ["UserProfile"],
  endpoints: (builder) => ({
    updateUserProfile: builder.mutation({
      query: (values) => ({
        url: "/api/v1/drive/users/",
        body: values,
        method: "PATCH",
      }),
      invalidatesTags: ["UserProfile"],
    }),
    deleteUserProfile: builder.mutation({
      query: () => ({
        url: "/api/v1/drive/users/",
        method: "DELETE",
      }),
    }),
    getUserProfile: builder.query({
      query: () => "/api/v1/drive/users/",
      providesTags: ["UserProfile"],
    }),
  }),
});

export const {
  useUpdateUserProfileMutation,
  useDeleteUserProfileMutation,
  useGetUserProfileQuery,
} = authApi;
