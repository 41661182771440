import { Wrapper } from "@/components/wrapper";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useSearchParams } from "react-router-dom";
import { useState } from "react";
import { toast } from "sonner";
import { useLazyGetChargerDetailQuery } from "@/redux/reducers/charger/charger.api";
import { Puff } from "react-loader-spinner";
import {
  setChargePointInfo,
  setIsCharger,
} from "@/redux/reducers/charger/charger.slice";
import { EmojiSad } from "iconsax-react";

export default function Home() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [chargerId, setChargerId] = useState();
  const { user } = useSelector((state) => state.auth);

  const [getChargerDetail, { isLoading, error, isError }] =
    useLazyGetChargerDetailQuery();

  useEffect(() => {
    if (!searchParams.get("charger")) {
      navigate("/account/auth");
      return;
    }

    setChargerId(searchParams.get("charger"));

    if (chargerId) {
      getChargerDetail(chargerId)
        .unwrap()
        .then((response) => {
          if (response.data.charge_point_status === "Available") {
            dispatch(setChargePointInfo(response.data));
            dispatch(setIsCharger(true));

            toast.success(response.message);
            if (user) {
              navigate("/plugin-charger");
            } else {
              navigate("/auth");
            }
          } else {
            toast.error(response.message);
            navigate("/busy");
            // dispatch(setChargePointInfo(null)); // need the chargepoint info (lat and long for busy)
          }
        })
        .catch((error) => {
          // toast.error(error.data.error_message);
        });
    }
  }, [chargerId, dispatch, getChargerDetail, navigate, searchParams, user]);

  return (
    <Wrapper>
      <div className="h-full w-full flex items-center justify-center">
        {isLoading ? (
          <Puff
            visible={true}
            height="120"
            width="120"
            color="#FFCA00"
            ariaLabel="puff-loading"
            wrapperStyle={{}}
            wrapperClass=""
          />
        ) : (
          isError && (
            <div className="flex flex-col items-center space-y-4">
              <div className="bg-[#cecece2d] p-2 rounded-3xl shadow-sm">
                <EmojiSad size="100" color="#FFCA00" />
              </div>
              <div className="space-y-4">
                <h2 className="text-center text-2xl font-bold">Uh oh!</h2>
                <p className="text-center text-sm font-semibold max-w-[250px]">
                  {error.data.error_message}
                </p>
              </div>
            </div>
          )
        )}
      </div>
    </Wrapper>
  );
}
