import "@/styles/app.css";
import { useEffect } from "react";
import { LoaderPage } from "@/components/loader-page";
import { ButtonGroup } from "@/components/button-group";
import { createBrowserRouter } from "react-router-dom";
import routes from "./routes";
import { RouterProvider } from "react-router-dom";
import { ErrorBoundary } from "react-error-boundary";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { setAppLoading } from "@/redux/reducers/auth/auth.slice";

function App() {
  const router = createBrowserRouter(routes);
  const dispatch = useDispatch();
  const { appLoading } = useSelector((state) => state.auth);

  useEffect(() => {
    dispatch(setAppLoading(true));
    const timeout = setTimeout(() => {
      dispatch(setAppLoading(false));
    }, 2000);
    return () => clearTimeout(timeout);
  }, [dispatch]);

  if (appLoading) return <LoaderPage />;

  return (
    <ErrorBoundary FallbackComponent={ErrorFallback}>
      <RouterProvider router={router}>
        <ButtonGroup />
      </RouterProvider>
    </ErrorBoundary>
  );
}

const ErrorFallback = ({ error, resetErrorBoundary }) => {
  return (
    <div role="alert">
      <p>Something went wrong:</p>
      <pre>{error.message}</pre>
      <button onClick={resetErrorBoundary}>Try again</button>
    </div>
  );
};

export default App;
