import { useSelector } from "react-redux";

function formatDateString(dateString) {
  // Parse the input date string
  const date = new Date(dateString);

  // Format the date using Intl.DateTimeFormat
  const options = {
    weekday: "long",
    year: "numeric",
    month: "long",
    day: "numeric",
  };
  const formattedDate = new Intl.DateTimeFormat("en-GB", options).format(date);

  return formattedDate;
}

export const PastSessionBox = ({ address, energyConsumed, cost, date }) => {
  const { currency } = useSelector((state) => state.wallet);
  return (
    <div className="w-full px-5 py-3 bg-[#CECECE2E] rounded-2xl flex items-center justify-between">
      <div>
        <p className="text-sm text-[#FFFFFFCC]">{address}</p>
        <div className="text-[9px] text-[#7E7E7E] flex gap-1">
          <span>{date ? formatDateString(date) : "Charging Ongoing"}</span>{" "}
          <span>|</span>
          <span>{energyConsumed} kWh</span>
        </div>
      </div>
      <div className="text-[13px] text-red-400">
        - {currency || ""}
        {cost}
      </div>
    </div>
  );
};
