import { configureStore } from "@reduxjs/toolkit";
import { combineReducers } from "redux";
import { persistReducer, persistStore } from "redux-persist";
import storage from "redux-persist/lib/storage"; // defaults to localStorage for web
import sessionStorage from "redux-persist/lib/storage/session"; // sessionStorage for web
import authSliceReducer from "./reducers/auth/auth.slice";
import chargerSliceReducer from "./reducers/charger/charger.slice";
import { api } from "../lib/utils/api";
import { authApi } from "./reducers/auth/auth.api";
import { chargerApi } from "./reducers/charger/charger.api";
import walletSliceReducer from "./reducers/wallet/wallet.slice";
import { walletApi } from "./reducers/wallet/wallet.api";
import sessionSliceReducer from "./reducers/session/session.slice";

// persist config for slices that should reset after the session ends
const sessionPersistConfig = {
  key: "session",
  storage: sessionStorage,
  blacklist: ["api"],
};

const appReducer = combineReducers({
  auth: authSliceReducer,
  charger: chargerSliceReducer,
  wallet: walletSliceReducer,
  session: sessionSliceReducer,
  [api.reducerPath]: api.reducer,
});

const rootReducer = (state, action) => {
  if (action.type === "USER_LOGOUT") {
    storage.removeItem("persist:root");
    state = undefined;
  }

  return appReducer(state, action);
};
const persistedReducer = persistReducer(sessionPersistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }).concat([
      authApi.middleware,
      chargerApi.middleware,
      walletApi.middleware,
    ]),
});

export const persistor = persistStore(store);
