import { Dialog, DialogContent, DialogFooter } from "@/components/ui/dialog";
import { Warning2 } from "iconsax-react";
import { Link } from "react-router-dom";
import { Button, buttonVariants } from "@/components/ui/button";
import { cn } from "@/lib/utils/cn";
import { useDispatch } from "react-redux";
import { setIsBalanceTopUp } from "@/redux/reducers/charger/charger.slice";
import { useState } from "react";
import { useGetWalletQuery } from "@/redux/reducers/wallet/wallet.api";
import { useEffect } from "react";
import { useSelector } from "react-redux";

export const Lowbalance = ({ handleStopCharging }) => {
  const dispatch = useDispatch();

  const { meterValues } = useSelector((state) => state.session);
  const { isCharging } = useSelector((state) => state.charger);

  const { data: walletData } = useGetWalletQuery(undefined);

  const [isWalletEmpty, setIsWalletEmpty] = useState(false);

  useEffect(() => {
    if (walletData) {
      setIsWalletEmpty(parseFloat(walletData.data?.balance) < 0.0001);
    }

    // Ckeck realtime data for wallet balance calculation to determine whether wallet is lower thna the price of chargeing and ensures the user has a minimun of '5' remaining
    if (isCharging) {
      handleStopCharging();
      setIsWalletEmpty(
        parseFloat(walletData.data?.balance) < meterValues.total_price + 5
      );
    }

    return () => {
      setIsWalletEmpty(false);
    };
  }, [handleStopCharging, isCharging, meterValues, walletData]);

  return (
    <Dialog modal open={isWalletEmpty}>
      <DialogContent className="max-w-xs p-5 backdrop-blur-lg border border-dashed border-[#FFFFFF5E] rounded-2xl bg-[#CECECE2E] shadow-[0px_0px_20px_0px_#FED127_inset]">
        <div className="flex flex-col items-center space-y-6">
          <div>
            <Warning2 size={72} color="#f87171" />
          </div>
          <div className="space-y-3">
            <h2 className="text-center font-semibold text-xl text-red-400">
              Low balance
            </h2>
            <p className="text-center text-xs">
              Your wallet balance is 0.00 or less than (charging_per_hour)
            </p>
          </div>
        </div>
        <DialogFooter className="flex flex-row gap-2 items-center justify-end">
          <Button
            variant="outline"
            onClick={() => setIsBalanceTopUp(false)}
            className="bg-transparent"
          >
            Cancel
          </Button>
          <Link
            to="/account/wallet?fund-account=true"
            onClick={() => dispatch(setIsWalletEmpty(true))}
            className={cn(buttonVariants(), "bg-[#fecf2797] text-black")}
          >
            Top up
          </Link>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};
