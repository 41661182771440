import { ChargerBoxBusy } from "@/components/charger-boxes/charger-box-busy";
import { Wrapper } from "@/components/wrapper";

export default function Busy() {
  return (
    <Wrapper>
      <ChargerBoxBusy />
    </Wrapper>
  );
}
