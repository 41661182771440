import { useSelector } from "react-redux";

export const ChargerBoxesHeader = ({ status, chargePointId }) => {
  const { chargePointInfo } = useSelector((state) => state.charger);

  return (
    <div className="flex flex-col items-center justify-between space-y-1">
      {status === "free" ? (
        <div className="flex flex-col items-center justify-between space-y-1">
          <p className=" font-light text-sm">You have successfully scanned</p>
          <span className="text-[#7EBC78] text-xl font-bold">
            {chargePointInfo?.charge_point_id}
          </span>
          <p className="text-sm">at</p>
          <p className="text-sm font-bold capitalize">
            {chargePointInfo?.charge_point_address}
          </p>
        </div>
      ) : (
        status === "busy" && (
          <div className="flex flex-col items-center justify-between space-y-1">
            <span className="text-[#BC7878] text-xl font-bold">
              {chargePointInfo?.charge_point_id}
            </span>
            <p className="text-sm font-light">is currently unavailable</p>
            <p className="text-sm font-light">Please try a different charger</p>
          </div>
        )
      )}
    </div>
  );
};
