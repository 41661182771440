import { ChargerBoxesHeader } from "@/components/charger-boxes/charger-boxes-header";
import { Button } from "@/components/ui/button";
import { Wrapper } from "@/components/wrapper";
import {
  useRemoteStartTransactionMutation,
  useRemoteStopTransactionMutation,
} from "@/redux/reducers/charger/charger.api";
import {
  useGetPaymentsQuery,
  useGetWalletQuery,
} from "@/redux/reducers/wallet/wallet.api";
import { setPayments, setWallet } from "@/redux/reducers/wallet/wallet.slice";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "sonner";
import { Client } from "@stomp/stompjs";
import { useGetUserProfileQuery } from "@/redux/reducers/auth/auth.api";
import { Audio } from "react-loader-spinner";
import { PaymentBox } from "@/components/payment-box";

import { cn } from "@/lib/utils/cn";
import ChargingSession from "@/components/charging-sesion";
import {
  resetMeterValues,
  setTransactionId,
} from "@/redux/reducers/session/session.slice";
import { ChargerLocation } from "@/components/charger-location";
import { useNavigate } from "react-router-dom";
import {
  setIsCharger,
  setIsCharging,
  setTransaction,
} from "@/redux/reducers/charger/charger.slice";
import { Info } from "lucide-react";
import { Lowbalance } from "@/components/low-balance.modal";

const EXCHANGE_NAME = import.meta.env.VITE_REMOTE_EXCHANGE_NAME;
const START_TRANSACTION_ROUTING_KEY = import.meta.env
  .VITE_START_TRANSACTION_ROUTING_KEY;
const STOP_TRANSACTION_ROUTING_KEY = import.meta.env
  .VITE_STOP_TRANSACTION_ROUTING_KEY;
const TRANSACTION_ID_ROUTING_KEY = import.meta.env
  .VITE_TRANSACTION_ID_ROUTING_KEY;
const TRANSACTION_EXCHANGE_NAME = import.meta.env
  .VITE_TRANSACTION_EXCHANGE_NAME;
const RABBITMQ_URL = `wss://${import.meta.env.VITE_RABBITMQ_HOST}/ws`;
const RABBITMQ_USER = import.meta.env.VITE_RABBITMQ_USER;
const RABBITMQ_PASSWORD = import.meta.env.VITE_RABBITMQ_PASSWORD;
const RABBITMQ_VHOST = import.meta.env.VITE_RABBITMQ_VHOST;
const RABBITMQ_VHOST_DRIVE = import.meta.env.VITE_RABBITMQ_VHOST_DRIVE;

export default function StartCharger() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { chargePointInfo, isCharging, transaction } = useSelector(
    (state) => state.charger
  );
  const { wallet } = useSelector((state) => state.wallet);

  const { data: userData, isLoading: userLoading } = useGetUserProfileQuery();
  const { data: paymentsData, isLoading: paymentsLoading } =
    useGetPaymentsQuery({ page: 1 });
  const {
    data: walletData,
    isLoading: walletLoading,
    refetch,
  } = useGetWalletQuery(undefined);
  const [startCharging, { isLoading: startCharingLoading }] =
    useRemoteStartTransactionMutation();
  const [stopCharging, { isLoading: stopCharingLoading }] =
    useRemoteStopTransactionMutation();

  const [chargingLoading, setChargingLoading] = useState(false);
  // const [isCharging, setIsCharging] = useState(false);
  // const [messages, setMessages] = useState([]);
  const [clientx, setClientX] = useState();
  const [clienty, setClientY] = useState();
  // const [transaction, setTransaction] = useState();

  useEffect(() => {
    if (walletData) {
      dispatch(setWallet(walletData.data));
    }
    if (paymentsData) {
      dispatch(setPayments(paymentsData.data));
    }
  }, [dispatch, paymentsData, walletData]);

  useEffect(() => {
    const client1 = new Client({
      brokerURL: RABBITMQ_URL,
      connectHeaders: {
        login: RABBITMQ_USER,
        passcode: RABBITMQ_PASSWORD,
        host: RABBITMQ_VHOST,
      },

      onConnect: () => {
        setClientX(client1);
      },

      onStompError: (frame) => {
        toast.error("Broker reported error: " + frame.headers["message"]);
        toast.error("Additional details: " + frame.body);
      },
    });

    client1.activate();
    dispatch(resetMeterValues());

    return () => {
      client1.deactivate();
    };
  }, [dispatch]);

  useEffect(() => {
    const client2 = new Client({
      brokerURL: RABBITMQ_URL,
      connectHeaders: {
        login: RABBITMQ_USER,
        passcode: RABBITMQ_PASSWORD,
        host: RABBITMQ_VHOST_DRIVE,
      },

      onConnect: () => {
        setClientY(client2);
      },

      onStompError: (frame) => {
        toast.error("Broker reported error: " + frame.headers["message"]);
        toast.error("Additional details: " + frame.body);
      },
    });

    client2.activate();

    return () => {
      client2.deactivate();
    };
  }, []);

  const handleStartCharging = () => {
    startCharging({
      charge_point_id: chargePointInfo.charge_point_id,
      connector_id: 1,
      id_tag: userData.data.id_tag,
    })
      .unwrap()
      .then((response) => {
        dispatch(resetMeterValues());
        setChargingLoading(true);
        toast.success(response.message);

        // Remote Start Transaction Event Contract
        if (clientx)
          clientx.subscribe(
            `/exchange/${EXCHANGE_NAME}/${START_TRANSACTION_ROUTING_KEY}`,
            (message) => {
              // setMessages((prevMessages) => [
              //   ...prevMessages,
              //   JSON.parse(message.body),
              // ]);

              const parsedMessage = JSON.parse(message.body);
              if (parsedMessage.status == "Accepted") {
                dispatch(setIsCharging(true));
                setChargingLoading(false);
                dispatch(setIsCharger(true));
                toast.success(parsedMessage.status);
              } else {
                dispatch(setIsCharging(false));
                setChargingLoading(false);
                toast.error(parsedMessage.status);
              }
            }
          );

        // Retrieve Transaction ID at start of chargin session Contract
        if (clienty)
          clienty.subscribe(
            `/exchange/${TRANSACTION_EXCHANGE_NAME}/${TRANSACTION_ID_ROUTING_KEY}`,
            (message) => {
              dispatch(
                setTransactionId(JSON.parse(message.body).transaction_id)
              );

              const txn = JSON.parse(message.body);
              dispatch(setTransaction(txn));
            }
          );
      });
    // .catch((error) => {
    //   console.error(error);
    // });
  };
  const handleStopCharging = () => {
    stopCharging({
      charge_point_id: chargePointInfo.charge_point_id,
      transaction_id: transaction.transaction_id,
    })
      .unwrap()
      .then((response) => {
        toast.success(response.message);

        // Remote Stop Transaction Event Contract
        if (clientx)
          clientx.subscribe(
            `/exchange/${EXCHANGE_NAME}/${STOP_TRANSACTION_ROUTING_KEY}`,
            (message) => {
              const parsedMessage = JSON.parse(message.body);

              if (parsedMessage.status == "Accepted") {
                dispatch(setIsCharging(false));
                dispatch(setTransactionId(null));
                dispatch(setTransaction(null));
                toast.success(parsedMessage.status);
                refetch();
                const timeout = setTimeout(() => {
                  navigate("/complete-charge");
                }, 1000);

                return () => clearTimeout(timeout);
              } else {
                dispatch(setIsCharging(true));
                toast.error(parsedMessage.status);
              }
            }
          );
      })
      .catch((error) => {
        console.error(error);
      });
  };

  return (
    <Wrapper className="space-y-4">
      {paymentsLoading ? <></> : <></>}
      {walletLoading ? <></> : <></>}
      <div className=" p-4  rounded-2xl bg-[#CECECE30] flex flex-col items-center gap-1 w-full">
        <ChargerBoxesHeader status="free" />
      </div>

      {/* <ChargingComplete /> */}

      <ChargerLocation
        lat={parseFloat(chargePointInfo?.charge_point_latitude)}
        lng={parseFloat(chargePointInfo?.charge_point_longitude)}
      />

      <PaymentBox />

      <div
        className={cn(
          parseFloat(wallet?.balance) < 50.0 &&
            "blur-sm pointer-events-none select-none",
          "flex flex-col items-center gap-1"
        )}
      >
        <span className="flex gap-1 text-[10px] items-center text-[#666] text-center">
          <Info size={12} />
          <span>Charger meter values are updated at a 30 second interval</span>
        </span>
        <div className="flex flex-col gap-3 items-center w-full">
          <ChargingSession />

          {isCharging ? (
            <Button
              onClick={handleStopCharging}
              disabled={stopCharingLoading || userLoading}
              className="bg-[#BC787869] tracking-[4px] border border-[#CECECE30]"
            >
              Stop Charging
            </Button>
          ) : (
            <Button
              onClick={handleStartCharging}
              disabled={startCharingLoading || chargingLoading}
              className="bg-[#7EBC7869] tracking-[4px] border border-[#CECECE30]"
            >
              Start Charging
              {chargingLoading && (
                <Audio
                  height="16"
                  width="16"
                  radius="9"
                  color="grey"
                  ariaLabel="loading"
                  wrapperStyle
                  wrapperClass
                />
              )}
            </Button>
          )}
        </div>
      </div>

      <Lowbalance handleStopCharging={handleStopCharging} />
    </Wrapper>
  );
}

// +852 8765 4321
