import { useSelector } from "react-redux";
import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from "@/components/ui/card";
import { Wrapper } from "@/components/wrapper";
import { ChargerLocation } from "@/components/charger-location";

import { Dialog, DialogContent } from "@/components/ui/dialog";
import { useState } from "react";
import { useNavigate } from "react-router-dom";

export default function AvailableChargers() {
  const { chargersWithinDistanceData } = useSelector((state) => state.charger);
  const navigate = useNavigate();

  const [selectedChargerDetails, setSelectedChargerDetails] = useState(null);

  if (!chargersWithinDistanceData) {
    return navigate("/account/auth");
  }

  return (
    <Wrapper className="h-full ">
      <div className=" space-y-6">
        <h1 className="text-3xl text-center">Available Chargers</h1>
        <div className="overflow-y-scroll max-h-[550px] space-y-2">
          {chargersWithinDistanceData.map((charger, index) => (
            <Card
              key={index}
              onClick={() => setSelectedChargerDetails(charger)}
              className="hover:bg-black/50 duration-150"
            >
              <CardHeader className="flex justify-between">
                <div className="flex  justify-between">
                  <CardTitle>{charger.charge_point_id}</CardTitle>
                  <span className="text-[#666] text-[10px]">
                    {charger.distance}km distance
                  </span>
                </div>
              </CardHeader>
              <CardContent>
                <span className="text-[#666] text-xs capitalize">
                  {charger.address}
                </span>
              </CardContent>
            </Card>
          ))}
        </div>
      </div>

      <Dialog modal open={selectedChargerDetails}>
        <DialogContent
          className="max-w-sm border-[#333] rounded-lg"
          onClick={() => setSelectedChargerDetails(null)}
        >
          {selectedChargerDetails && (
            <Card>
              <CardHeader className="flex justify-between">
                <div className="flex  justify-between">
                  <CardTitle>
                    {selectedChargerDetails.charge_point_id}
                  </CardTitle>
                  <span className="text-[#666] text-[10px]">
                    {selectedChargerDetails.distance}km distance
                  </span>
                </div>
                <CardDescription>{selectedChargerDetails.id}</CardDescription>
              </CardHeader>
              <CardContent>
                <div className="space-y-1">
                  <ChargerLocation
                    lat={parseFloat(selectedChargerDetails.latitude)}
                    lng={parseFloat(selectedChargerDetails.longitude)}
                  />
                  <span className="text-[#666] text-xs capitalize">
                    {selectedChargerDetails.address}
                  </span>
                </div>
              </CardContent>
            </Card>
          )}
        </DialogContent>
      </Dialog>
    </Wrapper>
  );
}
