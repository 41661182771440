import { cn } from "@/lib/utils/cn";
import { Logo } from "./logo";

export const Wrapper = ({ children, className }) => {
  const ownerPages = [
    "/plugin-charger",
    "/start-charger",
    "/complete-charge",
    "/busy",
  ];
  const onChargerOwnerPage = () => {
    const currentPath = window.location.pathname;
    return ownerPages.some((page) => currentPath === page);
  };
  return (
    <div className="relative flex flex-col items-center justify-between space-y-3 h-full max-w-[350px] mx-auto">
      <Logo showChargerOwner={onChargerOwnerPage()} />
      <div className="h-full flex items-center justify-center w-full">
        <div className={cn("w-full", className)}>{children}</div>
      </div>
    </div>
  );
};
