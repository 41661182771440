import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  wallet: null,
  payments: null,
  currency: null,
};

const walletSlice = createSlice({
  name: "wallet",
  initialState: initialState,
  reducers: {
    setWallet: (state, action) => {
      state.wallet = action.payload;
    },
    setPayments: (state, action) => {
      state.payments = action.payload;
    },
    setCurrency: (state, action) => {
      state.currency = action.payload;
    },
  },
});

export const { setWallet, setPayments, setCurrency } = walletSlice.actions;
export default walletSlice.reducer;
