import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  chargePointUrl: null,
  scanned: false,
  chargePointInfo: null,
  isCharger: false,
  isCharging: false,
  isBalanceTopUp: false,
  transaction: null,
  chargersWithinDistanceData: null,
};

const chargerSlice = createSlice({
  name: "charger",
  initialState: initialState,
  reducers: {
    setScanInfo: (state, action) => {
      state.chargePointUrl = action.payload;
      state.scanned = true;
    },
    setIsCharger: (state, action) => {
      state.isCharger = action.payload;
    },
    setChargePointInfo: (state, action) => {
      state.chargePointInfo = action.payload;
    },
    setIsCharging: (state, action) => {
      state.isCharging = action.payload;
    },
    setTransaction: (state, action) => {
      state.transaction = action.payload;
    },
    setChargersWithinDistanceData: (state, action) => {
      state.chargersWithinDistanceData = action.payload;
    },
    setIsBalanceTopUp: (state, action) => {
      state.isBalanceTopUp = action.payload;
    },
  },
});

export const {
  setScanInfo,
  setChargePointInfo,
  setIsCharging,
  setIsCharger,
  setTransaction,
  setChargersWithinDistanceData,
  setIsBalanceTopUp,
} = chargerSlice.actions;
export default chargerSlice.reducer;
