import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  user: null,
  signinConfirmation: null,
  token: null,
  loading: false,
  error: null,
  userProfile: null,
  isAuthenticated: false,
  appLoading: false,
  userPhoneNumber: null,
  verifier: null,
  hasCheckedProfile: false,
};

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setAuthLoading: (state, action) => {
      state.loading = action.payload;
    },
    setUser: (state, action) => {
      state.user = action.payload;
    },
    setUserProfile: (state, action) => {
      state.userProfile = action.payload;
    },
    setSigninConfirmation: (state, action) => {
      state.signinConfirmation = action.payload;
    },
    setToken: (state, action) => {
      state.token = action.payload;
    },
    setAuthError: (state, action) => {
      state.error = action.payload;
    },
    setIsAuthenticated: (state, action) => {
      state.isAuthenticated = action.payload;
    },
    setAppLoading: (state, action) => {
      state.appLoading = action.payload;
    },
    setPhoneNumber: (state, action) => {
      state.userPhoneNumber = action.payload;
    },
    setVerifier: (state, action) => {
      state.verifier = action.payload;
    },
    setHasCheckedProfile: (state, action) => {
      state.hasCheckedProfile = action.payload;
    },
  },
});

export const {
  setUser,
  setSigninConfirmation,
  setAuthLoading,
  setAuthError,
  setToken,
  setUserProfile,
  setIsAuthenticated,
  setAppLoading,
  setPhoneNumber,
  setVerifier,
  setHasCheckedProfile,
} = authSlice.actions;
export default authSlice.reducer;
